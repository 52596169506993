<template>
    <div class="cart-card">
        <div class="cart-card-header">
            <div style="font-size: 20px; font-weight: 600">
                <span>我的购物车</span>
                <span style="color: #FF9800; margin-left: 6px">{{total}}</span>
            </div>
            <div style="display: flex; margin-bottom: 16px">
                <span class="hand op" style="color: #2970FF; height: 30px; line-height: 30px" @click="utils.a('/shop/index')">再逛逛 ></span>
<!--                <span style="margin: 0 10px 0 20px; height: 30px; line-height: 30px">配送至：</span>-->
<!--                <el-select style="width: 260px" v-model="address" size="small" placeholder="请选择收货地址">-->
<!--                    <el-option-->
<!--                        v-for="item in addressList"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value"-->
<!--                        :disabled="item.disabled">-->
<!--                    </el-option>-->
<!--                </el-select>-->
            </div>
        </div>
        <div>
            <div class="title-card">
                <div class="title-content" style="width: 40%">
                    <el-checkbox @change="selectAll" v-model="isAll" style="float: left;">全选</el-checkbox>
                    <span>商品信息</span>
                </div>

                <div class="title-content" style="width: 15%">
                    <span>单价（元）</span>
                </div>
                <div class="title-content" style="width: 15%">
                    <span>数量</span>
                </div>
                <div class="title-content" style="width: 15%">
                    <span>金额（元）</span>
                </div>
                <div class="title-content" style="width: 15%">
                    <span>操作</span>
                </div>
            </div>
            <div v-for="(items, indexs) in cartList" :key="indexs" style="margin: 20px 0 30px 0;">
                <div style="margin-bottom: 10px;overflow: hidden" v-if="items.beFrom === 3">
                    <div style=" float: left; width: 24px; height: 24px; border-radius: 50%"></div>
                    <div style="float: left;margin-right: 10px; font-weight: 600">{{items.name}}</div>
                    <div class="tag" style="background-color: #2970FF;">企业</div>
                    <div class="tag" style="background-color: #7BD7A2;">实地认证</div>
                    <div class="tag" style="background-color: #FF9800;">10年老店</div>
                </div>
                <div style="margin-bottom: 10px;overflow: hidden; color: #031f88" v-if="items.beFrom === 1">
                    <div style=" float: left; margin-right: 6px"><i class="el-icon-s-home"></i></div>
                    <div style="float: left;margin-right: 10px; font-weight: 600">{{items.name}}</div>
                </div>
                <div style="margin-bottom: 10px;overflow: hidden; color: #031f88" v-if="items.beFrom === 2">
                    <div style=" float: left; margin-right: 6px"><i class="el-icon-s-claim"></i></div>
                    <div style="float: left;margin-right: 10px; font-weight: 600">{{items.name}}</div>
                </div>
                <div :class="item.select ? 'content-card' : 'content-card-select'" v-for="(item, index) in items.goodsList" :key="index">
                    <div style="display: flex; width: 40%">
                        <el-checkbox :disabled="item.checkGoodsInfo.goodsStatus > 1" v-model="item.select" @change="handleCheckedCitiesChange($event, item.id)" style="margin-right: 20px; line-height: 88px; width: 2%"></el-checkbox>
                        <div style="width: 19%; height: 88px; margin-right: 10px">
                            <img style="width: 100%" src="">
                        </div>
                        <div style="width: 69%">
                            <div>{{item.goodsName}}</div>
                            <div style="color: #999999; margin-top: 10px">
                                <span v-for="(key, index) in item.skuList.specValues" :key="index">
                                    {{Object.keys(key)[0]}}: {{Object.values(key)[0]}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center; font-weight: 600; width: 15%">
                        ￥{{item.goodsUnitPrice}}
                    </div>
                    <div style="text-align: center; font-weight: 600; width: 15%">
                        <el-input-number size="small" @change="(newVal, oldVal) => {changeCount(item,indexs, index, newVal, oldVal)}" step-strictly v-model="item.goodsCount" :min="1"></el-input-number>
                        <div v-if="item.checkGoodsInfo.goodsStatus > 1" style="margin-top: 12px; font-size: 14px; color: #FF444C">{{item.checkGoodsInfo.msg}}</div>
                    </div>
                    <div style="text-align: center; font-weight: 600; width: 15%">
                        ￥{{utils.keepTwoDecimalFull(item.goodsCount * item.goodsUnitPrice)}}
                    </div>
                    <div style="text-align: center; width: 15%">
                        <div class="hand op" @click="deleteGoods([item.id])">移除</div>
                    </div>
                </div>
            </div>
            <div style="display: flex; margin-top: 30px">
                <div style="width: 88%; display: flex; padding: 6px 20px; background-color: #F8F8F8; justify-content: space-between">
                    <div class="title-content" style="margin-top: 10px">
                        <span style="margin-right: 30px" @click="deleteGoods(getSelectGoods())">删除选中的产品</span>
                        <span style="margin-right: 30px" @click="clearCart">清空购物车</span>
                    </div>
                    <div style="display: flex">
                        <div style="font-size: 12px; margin-right: 30px; margin-top: 2px">
                            已选择 <span style="color: #EE3F4D">{{count}}</span> 件产品
                        </div>
                        <div style="margin-right: 30px">
                            总价：<span style="color: #EE3F4D; font-size: 18px; font-weight: 600">￥{{num}}</span>
                            <div style="margin-top: 2px; font-size: 12px; color: #666666">产品运输费用：￥400</div>
                        </div>
                    </div>
                </div>
                <div @click="toCreateOrder" class="hand op" style="padding: 14px 40px;font-size: 20px; font-weight: 600; background-color: #EE3F4D; color: #FFFFFF">去下单</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CartCard",
    components: {},
    data() {
        return {
            address: '',
            total: 0,
            num: '0.00',
            count: 0,
            isAll: false,
            addressList: [
                {
                    value: 1,
                    label: '新疆乌鲁木齐市天山区汇嘉时代',
                },
                {
                    value: 2,
                    label: '新疆乌鲁木齐市沙依巴克区万科都会传奇',
                }
            ],
            cartList: [],

            indeterminate: false,
            selectList: [],
    }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getCartList()
    },
    methods: {
        // 购物车下单
        toCreateOrder() {
            let goods = this.getSelectGoods();
            if (goods.length > 0) {
                this.$router.push({
                    name: 'CartCreateOrder',
                    query: {goods: JSON.stringify(goods)}
                })
            } else {
                this.utils.msg('请选择产品')
            }
        },

        // 清空购物车
        clearCart() {
            let that = this;
            this.utils.confirm('确定要清空购物车吗', function () {
                let goodList = [];
                for (const goods of that.cartList) {
                    goods.goodsList.find((item) => {
                        goodList.push(item.id)
                    });
                }
                that.newApi.removeGoodsCart({cartIdList: JSON.stringify(goodList)}).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.cartList = [];
                        that.getSum();
                        that.getCount();
                    }
                })
            })
        },

        // 移除购物车商品
        deleteGoods(items) {
            let that = this;
            if (items.length > 0) {
                this.utils.confirm('确定删除此产品吗', function () {
                    that.newApi.removeGoodsCart({cartIdList: JSON.stringify(items)}).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.getCartList()
                        }
                    })
                })
            } else {
                this.utils.msg('请选择产品')
            }
        },

        // 获取购物车列表
        getCartList() {
            this.newApi.goodsCartList().then(res => {
                this.cartList = res.data
                this.total = 0
                for (const item of res.data) {
                    this.total += item.goodsList.length
                }
                this.selectAll(false)
                this.getSum();
                this.getCount();
            })
        },

        // 查询选中的产品
        getSelectGoods() {
            let goodList = [];
            for (const goods of this.cartList) {
                goods.goodsList.find((item) => {
                    if (item.select) {
                        goodList.push(item.id)
                    }
                });
            }
            return goodList
        },

        // 全选
        selectAll(val) {
            for (const item of this.cartList) {
                item.goodsList.forEach(food => {
                    if (food.checkGoodsInfo.goodsStatus === 1) {
                        food.select = val;
                    }
                })
            }
            this.getSum();
            this.getCount();
        },

        // 多选
        handleCheckedCitiesChange(val, id) {
            for (const goods of this.cartList) {
                let result = goods.goodsList.map((item) => {
                    if (id == item.id) {
                        item.select = val;
                    }
                    return item.select;
                });
                let res = result.every((item) => {
                    return item;
                });
                if (res) {
                    this.isAll = true;
                } else {
                    this.isAll = false;
                }

            }
            this.getSum();
            this.getCount();
        },

        changeCount(item, indexs, index) {
            let param = {};
            param.type = 2;
            param.id = item.id;
            param.goodsId = item.goodsId;
            param.goodsCount = item.goodsCount;
            param.goodsPrice = this.utils.keepTwoDecimalFull(item.goodsCount * item.goodsUnitPrice);
            this.newApi.shopGoodsAddAndSubtract(param).then(res => {
                if (res.isSuccess === 1) {
                    this.getSum();
                    this.getCount();
                }
                this.cartList[indexs].goodsList[index].checkGoodsInfo = res.data.checkGoodsInfo
            })
        },

        // 计算库存

        // 计算价格
        getSum() {
            let num = 0;
            for (const goods of this.cartList) {
                goods.goodsList.find((item) => {
                    if (item.select) {
                        num += item.goodsUnitPrice * item.goodsCount;
                    }
                });
            }
            this.num = this.utils.keepTwoDecimalFull(num);
        },

        //计算总数
        getCount() {
            let count = 0;
            for (const goods of this.cartList) {
                goods.goodsList.find((item) => {
                    if (item.select) {
                        count += item.goodsCount;
                    }
                });
            }
            this.count = count;
        },
    }
}
</script>

<style scoped>
    .cart-card {
        width: 100%;
        overflow: hidden;
    }

    .cart-card .cart-card-header {
        display: flex;
        justify-content: space-between;
    }

    .cart-card .title-card {
        padding: 14px 20px;
        display: flex;
        border: 1px solid #DCDCDC;
    }

    .cart-card .title-content {
        font-size: 16px;
        text-align: center;
    }

    .cart-card .tag {
        float: left;
        margin-top: 1px;
        padding: 2px 6px;
        font-size: 12px;
        border-radius: 5px;
        color: #FFFFFF;
        margin-right: 10px;
    }

    .cart-card .content-card {
        padding: 20px 20px;
        display: flex;
        background-color: #FFFBF0;
        border: 1px solid #FF9800;
    }

    .cart-card .content-card-select {
        padding: 20px 20px;
        display: flex;
        background-color: #F8F8F8;
        border: 1px solid #DCDCDC;
    }
</style>