<template>
    <div class="cart">
        <div class="cart-body">
            <div style="display: flex; justify-content: space-between; margin-bottom: 60px">
                <div class="input" style="margin-right: 40px">
                    <el-input placeholder="请输入你想要查询的内容以获得服务帮助..." v-model="search" style="width: 1000px; border-color: #FF9800" >
                        <el-button style="border: 1px solid #FF9800; border-radius: 0 5px 5px 0; font-size: 16px; color: #FFFFFF ; background-color: #FF9800" slot="append"><i class="el-icon-search" style="margin-right: 6px"></i>搜索</el-button>
                    </el-input>
                </div>
                <div style="display: flex">
                    <div class="hand op btn" style="background-color: #2970FF;">发布商业需求</div>
                    <div style="height: 40px; line-height: 40px; margin: 0 20px">或</div>
                    <div class="hand op btn" style="background-color: #2970FF">服务方入住</div>
                </div>
            </div>
        </div>

        <div style="width: 1500px; margin: 0 auto">
            <CartCard></CartCard>
        </div>
    </div>
</template>

<script>

import CartCard from "../components/cart/CartCard";
export default {
    name: "index",
    components: {CartCard},
    data() {
        return {
            search: '',
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {

    }
}
</script>

<style scoped>
    .cart {
        min-height: 100vh;
        background-color: #FFFFFF;
    }

    .cart .cart-body {
        width: 1500px;
        margin: 0 auto;
        padding-top: 120px;
    }

    .cart .cart-body .btn {
        padding: 10px 30px;
        font-size: 16px;
        color: #FFFFFF;
        border-radius: 5px;
    }

    /deep/ .input .el-input__inner {
        border:1px solid #FF9800;
    }
</style>
